import Vue from "vue";
import "./plugins";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./registerServiceWorker";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "./assets/global.scss";
import Keycloak from "keycloak-js";
import { callFunction } from "./components/common/api";
import {ide_log} from './components/common/ide_log';
import authentication from "@/plugins/authentication";

Vue.config.productionTip = false;
// new Vue({
//   router,
//   store,
//   vuetify,
//   render: (h) => h(App),
//   beforeCreate() {},
// }).$mount("#app");
Vue.use(authentication);

Vue.$keycloak
  .init({ onLoad: "login-required", flow: "implicit", checkLoginIframe: false })
  .then( async () => {
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");

    localStorage.setItem(
      "username",
      Vue.$keycloak.idTokenParsed.preferred_username
    );

    //console.log(Vue.$keycloak.token);

    var ngaytao = Math.round(new Date().getTime() / 1000);

    try {
      if (Vue.$keycloak.idTokenParsed.preferred_username != null) {
        var statusLogin = localStorage.getItem("statusLogin");
        //localStorage.setItem("sequence", 0);
        if (statusLogin != 1) {
          // var data = ide_log("login", "","",0, "",1);
          // console.log(data);
          console.log( await ide_log("login", "","",0, "",1) );
          localStorage.setItem("statusLogin", 1);
        }
      }
    } catch (err) {
      console.log(err);
    }
  });

// console.log(process.env.VUE_APP_SSO_URL);
// console.log(process.env.VUE_APP_SSO_REALM);
// console.log(process.env.VUE_APP_SSO_CLIENT_ID);

function jsonFunction(objectname='',objectpk='', ngaytao='', nguoitao='', noidung='', thongtin='', t_phanhoi='', server_='', sequence='', trangthai=''){
    var data = {
        func: "87",
        body: {
          sequence: sequence,
          objectpk: objectpk,
          noidung: noidung,
          trangthai: trangthai,
          t_phanhoi: t_phanhoi,
          server_: server_,
          thongtin: thongtin,
          ngaytao: ngaytao,
          nguoitao: nguoitao,
          objectname: objectname,
        },
    };
    return data;
}
export {jsonFunction}
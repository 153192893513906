<template>
  <v-main>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo-leanbot-large.png')"
          class="my-3"
          contain
          height="100"
        />
      </v-col>
      <v-col md="8" lg="6" class="text-center">
        <h1 class="text-h1">Welcome to Leanbot IDE</h1>
      </v-col>
      <v-col cols="12">&nbsp;</v-col>
      <v-col cols="auto" class="text-center">

        <p class="text-subtitle-1">
          To upload your first code you must<br>Để tải code vào Leanbot 
        </p>
        <ol class="text-left">
          <!-- <li>Tài khoản: {{keycloaks.idTokenParsed.preferred_username}}</li>
          <li>Firstname: {{keycloaks.idTokenParsed.given_name}}</li>
          <li>Lastname: {{keycloaks.idTokenParsed.family_name}}</li>
          <li>Email: {{keycloaks.idTokenParsed.email}}</li> -->
          <li>Create a project and write your code<br>Tạo một project và viết code</li>
          <li>Select a serial port to upload to<br>Chọn cổng serial để tải code vào Leanbot</li>
          <li>Press the upload button in the top right<br>Bấm nút upload ở góc trên bên phải</li>
        </ol>
      </v-col>
      <v-col cols="12">&nbsp;</v-col>

    </v-row>
  </v-main>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  //name: 'Home',
  //props: ['keycloaks'],
  data() {
    return {
      discordLink: 'https://discord.gg/FKQp7N4',
    };
  },
  methods: {
    ...mapMutations(['toggleDonateMenu']),
  },
};
</script>
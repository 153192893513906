<template>
  <div>
    <v-progress-linear :value="percentage * 100" height="25">
      <strong>{{ Math.ceil(percentage * 100) }}% - {{ message }}</strong>
      <input type="hidden" name="msg" id="msg" :value="message" />
      <input type="hidden" name="code" id="code" :value="code" />
    </v-progress-linear>
    <terminal ref="term" :height="height" />
  </div>
</template>

<script>
import { callFunction } from "../common/api";
import Terminal from "../terminal/terminal.vue";
import debouncePromise from "debounce-promise";
import get from "lodash/get";
import Ide from "../ide.vue";
import Vue from 'vue';
import $ from 'jquery';
import { mapMutations } from "vuex";
export default {
  components: {
    Terminal,
    Ide,
  },
  props: {
    height: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      percentage: 1,
      message: "Ready",
      progressCB: null,
      logCB: null,
      clearCB: null,
      showOnce: false,
    };
  },
  beforeMount() {
    this.token = Vue.$keycloak.token
    this.username = Vue.$keycloak.idTokenParsed.preferred_username
  },
  computed: {
    settings() {
      const { Setting } = this.$FeathersVuex.api;
      const { data } = Setting.findInStore({ query: { key: "editor" } });
      if (data[0]) return data[0].value;
      return {};
    },
    currentFile() {
      const { File } = this.$FeathersVuex.api;
      return (
        File.findInStore({ query: { uuid: this.$store.getters.currentFile } })
          .data[0] || {}
      );
    },
    currentServer() {
      const { Server } = this.$FeathersVuex.api;
      return Server.findInStore({
        query: { uuid: this.$store.getters.currentServer },
      }).data[0];
    },
    code() {
      return this.currentFile?.body ?? "";
    },
  },
  mounted() {
    this.progressCB = (data) => {
      this.percentage = data.percent;
      this.message = data.message;
      console.log(data.message);
      localStorage.setItem("server_", this.currentServer.address);
      if (data.percent == 1) {
        var status;
        var t1 = localStorage.getItem("t1")

        var t2 = new Date().getTime();

        var totalT = t2-t1;
        if (data.message == "Done!") {
          status = 1;
          localStorage.setItem("statusCompile", status);
        } else {
          
          status = 0;
        }
        
        

        var date = new Date(totalT);

        var ms = date.getMilliseconds();

        const codeContent = document.querySelector("#code");
        var contentCode = {
          code: codeContent.value,
        };
        var ngaytao = Math.round(new Date().getTime() / 1000);
        var jsonCode = JSON.stringify(contentCode);
        var infoLog = localStorage.getItem("infoLog")
        var objectpk = localStorage.getItem("objectpk")
        
        try{
          var data = {
            func: "87",
            body: {
              objectname: "ide",
              objectpk: objectpk,
              nguoitao: Vue.$keycloak.idTokenParsed.preferred_username,
              noidung: codeContent.value,
              thongtin: infoLog,
              t_phanhoi: totalT,
              server_: this.currentServer.address,
              trangthai: status,
            },
          };
          var jsonRequest_1 = btoa(JSON.stringify(data));
          var bodyJson = {
            jsonRequest: jsonRequest_1,
          };
          //callFunction(process.env.VUE_APP_SSO_API_POST,'POST',bodyJson, Vue.$keycloak.token);
        }catch(err){
          console.log(err);
        }
        
      }
    };
    this.$compiler.on("console.progress", this.progressCB);
    this.logCB = (data) => {
      this.$refs.term.write(data);
    };
    this.$compiler.on("console.log", this.logCB);
    this.$compiler.on("console.error", this.logCB);
    this.clearCB = () => {
this.$refs.term.clear();
    };
    this.$compiler.on("console.clear", this.clearCB);
    setTimeout(() => {
      this.$refs.term.write(
        "Press the compile/program button above to begin.\n\r"
      );
    }, 500);
  },
  beforeDestroy() {
    if (this.progressCB)
      this.$compiler.off("console.progress", this.progressCB);
    if (this.logCB) {
      this.$compiler.off("console.log", this.logCB);
      this.$compiler.off("console.error", this.logCB);
    }
    if (this.clearCB) this.$compiler.off("console.clear", this.clearCB);
  },
};
</script>

import {callFunction} from './api';
import {jsonFunction} from './function';
import Vue from 'vue';
import { timers } from 'jquery';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
async function sleepMs(durationMs = 0) {
    await sleep(durationMs);
}
async function ide_log(objectpk='', noidung='', thongtin='', t_phanhoi='', server_='', trangthai=''){
  try{
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours()+':'+today.getMinutes()+':'+today.getSeconds()+'.'+today.getMilliseconds();
      var ngaytao = date + ' '+ time;
      
      try {
        var sequence1 = parseInt(localStorage.getItem("sequence"));
        if ( isNaN(sequence1) ) {
          sequence1 = 0;
        }
      } catch (err) {
        var sequence1 = 0;
      }
      var sequence2 = sequence1 + 1;
      localStorage.setItem("sequence", sequence2);
    
      var data = jsonFunction("ide",objectpk,ngaytao,Vue.$keycloak.idTokenParsed.preferred_username, noidung,thongtin,t_phanhoi, server_,sequence2, trangthai);
      //console.log(data);
      let jsonRequest_1 = btoa(JSON.stringify(data));					//  let (not var) - new instance for each async call

      var logDelay = 0;
      if ( objectpk === 'serial_connect' ) logDelay = 10000;
      setTimeout( () => {
        callFunction(
          process.env.VUE_APP_SSO_API_POST,
          "POST",
          jsonRequest_1,
          Vue.$keycloak.token
        );
      }, logDelay);
    
      await sleepMs(500);
  } catch (err) {
      console.log(err);
  }
  return data.body;
}


export {ide_log}

export {sleepMs}

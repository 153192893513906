import $ from 'jquery';
function callFunction(url,method, jsonRequest='', token=''){
    $.ajax({
        type: method,
        url: url,   
        contentType : "application/json; charset=utf-8;",  
        headers:{
             
             "Content-Type": "application/x-www-form-urlencoded",
              "Authorization": "Bearer "+token      		 
        },
        data: {
            "jsonRequest":jsonRequest
        },
        success: function (response) {	
        }
    });
    
}
export {callFunction}
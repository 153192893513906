var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',_vm._b({scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","text":"","disabled":!_vm.valid,"id":"btn_checkCompile"},on:{"click":_vm.run}},[_c('v-icon',[_vm._v("mdi-code-tags-check")])],1)],1)]}}])},'v-tooltip',{ top: _vm.top, bottom: _vm.bottom },false),[_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v("Check & Compile")]),_c('br'),_c('span',{class:{
        'error--text': !this.currentServer,
        'success--text': !!this.currentServer,
      }},[_c('v-icon',{attrs:{"color":this.currentServer ? 'success' : 'error',"small":""}},[_vm._v(" mdi-"+_vm._s(this.currentServer ? "check" : "close")+"-circle-outline ")]),_vm._v(" Server Selected ")],1),_c('br'),_c('span',{class:{
        'error--text': !this.currentBoard,
        'success--text': !!this.currentBoard,
      }},[_c('v-icon',{attrs:{"color":this.currentBoard ? 'success' : 'error',"small":""}},[_vm._v(" mdi-"+_vm._s(this.currentBoard ? "check" : "close")+"-circle-outline ")]),_vm._v(" Board Selected ")],1),_c('br'),_c('span',{class:{
        'error--text': !this.currentProject,
        'success--text': !!this.currentProject,
      }},[_c('v-icon',{attrs:{"color":this.currentProject ? 'success' : 'error',"small":""}},[_vm._v(" mdi-"+_vm._s(this.currentProject ? "check" : "close")+"-circle-outline ")]),_vm._v(" Project Selected ")],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }